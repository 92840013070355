import { useCallback } from 'react';
import { ClientFormTag, ClientFormUser } from '../models/ClientFormUser';
import ClientFormService from '../services/ClientFormService';
import { useCurrentFormUsers, useFormSectionUsers, useClientFormUserTags } from '../global-state/Forms';
import { useFetchActiveClientGroups } from './useFetchActiveClientGroups';

const useFormUsers = () => {
  const { data: clientGroupings = [] } = useFetchActiveClientGroups();
  const setFormUsers = useCurrentFormUsers((x) => x.setValue);
  const setFormSectionUsers = useFormSectionUsers((x) => x.setValue);
  const setFormUserGroupings = useClientFormUserTags((x) => x.setValue);

  return useCallback(
    (clientFormId: string) => {
      ClientFormService.getFormUsers(clientFormId).then((res) => {
        const users = res.data.flatMap((x) => x.users.map((u) => ({ ...u, ...u.user, role: x.role }) as ClientFormUser));
        const formUsers: ClientFormUser[] = [];
        const formSectionUsers: ClientFormUser[] = [];
        for (const user of users) {
          (user.formSectionId ? formSectionUsers : formUsers).push(user);
        }
        setFormUsers(formUsers);
        setFormSectionUsers(formSectionUsers);

        const tags: ClientFormTag[] = res.data.flatMap((x) =>
          // use the clientGroupings's users, as if the users in the response were added individually, they would not be in the grouping
          x.tags.map((t) => ({
            ...t,
            role: x.role,
            users: clientGroupings.find((g) => g.id === t.id)?.users.map((u) => ({ ...u, role: x.role })) ?? [],
          })),
        );
        setFormUserGroupings(tags);
      });
    },
    [clientGroupings, setFormSectionUsers, setFormUserGroupings, setFormUsers],
  );
};

export default useFormUsers;
