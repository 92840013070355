import { FC, useCallback, useEffect, useMemo } from 'react';
import { TabStrip } from '../../shared/tab-strip/TabStrip';
import { useTranslation } from 'react-i18next';
import { ClientForm } from '../../../models/ClientForm';
import { FormSection, PeriodicReviewChecklistItemInstance, Progress } from '../../../models/Form';
import FormStepsRenderer from '../FormStepsRenderer';
import CondensedActivityDetails from './CondensedActivityDetails';
import CondensedAssociations from './CondensedAssociations';
import { FormType } from '../../../models/FormTypes';
import FormVersionHistory from '../FormVersionHistory';
import CondensedAttachments from './CondensedAttachments';
import { Associations } from '../../../models/Association';
import { PeriodicReviewStatus } from '../../../models/PeriodicReview';
import PeriodicChecklistViewer from '../PeriodicChecklistViewer';
import { Risk } from '../../../models/Risk';
import CondensedRisks from './CondensedRisks';
import { Roles } from '../../../models/Role';
import usePermissions from '../../../hooks/permissions/usePermissions';
import { EventSystem } from '../../../events/EventSystem';
import { useNavigate } from 'react-router';
import CondensedDistribution from './CondensedDistribution';
import { DistributionResponse } from '../../../models/Distribution';
import { useFeatureFlags } from '../../../contexts/FeatureFlagContext';
import { DocumentResponse } from '../../../models/Document';
import { useCurrentFormUsers, useFormSectionUsers, useViewDistributionLogs } from '../../../global-state/Forms';
import { useShallow } from 'zustand/react/shallow';
import { useCurrentUser } from '../../../global-state/Auth';

type Props = {
  clientForm: ClientForm;
  onChecklistChanged: (checklist: PeriodicReviewChecklistItemInstance[]) => void;
  fetchForm: () => void;
  associations: Associations[];
  allFlaggedRisks: Risk[];
  onDistributionUpdated: (distribution: DistributionResponse) => void;
} & (
  | {
      visibleSteps: FormSection[];
      currentStepIndex: number;
      onStepChange: (newValue: number) => void;
      visibleStepsIds: string[];
      stepProgress: Record<string, Progress>;
      viewOnly?: false;
    }
  | {
      visibleSteps?: FormSection[];
      currentStepIndex?: number; //Optional when viewOnly is true
      onStepChange?: (newValue: number) => void; //Optional when viewOnly is true
      visibleStepsIds?: string[]; //Optional when viewOnly is true
      stepProgress?: Record<string, Progress>; //Optional when viewOnly is true
      viewOnly: true;
    }
);

const ViewerLeftNav: FC<Props> = (props) => {
  const {
    clientForm,
    visibleSteps,
    currentStepIndex,
    onStepChange,
    visibleStepsIds,
    stepProgress,
    fetchForm,
    associations,
    viewOnly,
    onChecklistChanged,
    allFlaggedRisks,
    onDistributionUpdated,
  } = props;
  const { featureFlags } = useFeatureFlags();
  const { t } = useTranslation(['form']);
  const navigate = useNavigate();
  const hasPermission = usePermissions();
  const isSysTemplate = useMemo(
    () => clientForm?.form.isSystem && clientForm.form.type === FormType.Document,
    [clientForm?.form.isSystem, clientForm?.form.type],
  );
  const [viewDistributionLogs, setViewDistributionLogs] = useViewDistributionLogs(useShallow((x) => [x.value, x.setValue]));
  const currentUser = useCurrentUser((x) => x.value);
  const clientFormUsers = useCurrentFormUsers((x) => x.value);
  const clientSectionUsers = useFormSectionUsers((x) => x.value);

  const defaultTab = useMemo(() => {
    return clientForm.originId ? 'version-history' : isSysTemplate || viewOnly ? 'details' : 'overview';
  }, [clientForm.originId, isSysTemplate, viewOnly]);

  useEffect(() => {
    const handler = () => {
      navigate({ hash: 'risks' }, { replace: false });
    };

    EventSystem.listen('highlight-risk', handler);

    return () => {
      EventSystem.stopListening('highlight-risk', handler);
    };
  }, [navigate]);

  const onTabChange = useCallback(
    (newTabId: string) => {
      if (newTabId !== 'distribution' && viewDistributionLogs) {
        setViewDistributionLogs(false);
      }
    },
    [setViewDistributionLogs, viewDistributionLogs],
  );

  const hideVersionHistory = useMemo(() => {
    // If below TeamMember, and not on the clientForm, hide the history
    if (hasPermission(Roles.TeamMember)) return false;

    const users = clientFormUsers.concat(clientSectionUsers);
    if (users.find((x) => x.userId === currentUser?.id)) {
      return false;
    }

    return true;
  }, [clientFormUsers, clientSectionUsers, currentUser?.id, hasPermission]);

  return (
    <TabStrip enableHash allowWrapping borderless fillSelected defaultTabId={defaultTab} enableSticky onChange={onTabChange}>
      {!isSysTemplate && !viewOnly && <TabStrip.TabHeader id="overview" text={t('left-tabs.headings.overview')} />}
      <TabStrip.TabHeader id="details" text={t('left-tabs.headings.details')} />
      {!hideVersionHistory && <TabStrip.TabHeader id="version-history" text={t('left-tabs.headings.version-history')} />}
      <TabStrip.TabHeader id="attachments" text={t('left-tabs.headings.files')} />
      <TabStrip.TabHeader id="references" text={t('left-tabs.headings.associations')} />
      {clientForm.periodicReviewChecklist &&
        clientForm.periodicReviewChecklist.length > 0 &&
        (clientForm.periodicReviewStatus === PeriodicReviewStatus.UnderReview ||
          clientForm.periodicReviewStatus === PeriodicReviewStatus.ReviewOverdue) && (
          <TabStrip.TabHeader id="checklist" text={t('form:info-modal.tabs.checklist')} />
        )}
      <TabStrip.TabHeader id="risks" text={t('left-tabs.headings.risks')} />
      {clientForm.type === FormType.Document && featureFlags.distributionList && (
        <TabStrip.TabHeader id="distribution" text={t('left-tabs.headings.distribution')} />
      )}
      <TabStrip.TabContent forId="details">
        <CondensedActivityDetails clientForm={clientForm} />
      </TabStrip.TabContent>
      {!isSysTemplate && !viewOnly && (
        <TabStrip.TabContent forId="overview">
          <div className="mt-4 pr-4">
            <div className="px-4 pb-2 font-medium">{t('left-tabs.headings.overview')}</div>
            <div className="pl-2">
              {visibleSteps.length > 0 && (
                <FormStepsRenderer
                  clientFormId={clientForm.id}
                  form={clientForm.form}
                  onStepChange={onStepChange}
                  selectedStep={visibleSteps[currentStepIndex]}
                  statuses={clientForm.sectionStatuses}
                  visibleSteps={visibleSteps}
                  enabledStepIds={visibleStepsIds}
                  progress={stepProgress}
                  currentUserPermissionChanged={fetchForm}
                />
              )}
            </div>
          </div>
        </TabStrip.TabContent>
      )}
      {!hideVersionHistory && (
        <TabStrip.TabContent forId="version-history">
          <FormVersionHistory clientForm={clientForm} minimal />
        </TabStrip.TabContent>
      )}
      {clientForm && (
        <TabStrip.TabContent forId="attachments">
          <CondensedAttachments clientForm={clientForm} viewOnly={viewOnly} />
        </TabStrip.TabContent>
      )}
      <TabStrip.TabContent forId="references">
        <CondensedAssociations
          clientFormId={clientForm.id}
          associations={associations}
          viewOnly={viewOnly || clientForm.type !== FormType.Document}
        />
      </TabStrip.TabContent>
      {clientForm.periodicReviewChecklist &&
        clientForm.periodicReviewChecklist.length > 0 &&
        (clientForm.periodicReviewStatus === PeriodicReviewStatus.UnderReview ||
          clientForm.periodicReviewStatus === PeriodicReviewStatus.ReviewOverdue) && (
          <TabStrip.TabContent forId="checklist">
            <div className="truncate px-4">
              <PeriodicChecklistViewer
                clientFormId={clientForm.id}
                checklist={clientForm.periodicReviewChecklist}
                onChecklistChanged={onChecklistChanged}
              />
            </div>
          </TabStrip.TabContent>
        )}
      <TabStrip.TabContent forId="risks">
        <CondensedRisks
          risks={allFlaggedRisks}
          viewOnly={(!hasPermission([Roles.Employee, Roles.ExternalContributor]) && hasPermission(Roles.ExternalAuditor)) || !!clientForm.originId}
        />
      </TabStrip.TabContent>
      {clientForm.type === FormType.Document && featureFlags.distributionList && (
        <TabStrip.TabContent forId="distribution">
          <CondensedDistribution
            clientForm={clientForm as DocumentResponse}
            onDistributionUpdated={onDistributionUpdated}
            viewOnly={!!clientForm.originId}
          />
        </TabStrip.TabContent>
      )}
    </TabStrip>
  );
};

export default ViewerLeftNav;
