import { FC, ReactElement } from 'react';
import InfoIcon from '../../shared/icon/InfoIcon';
import Tooltip from '../../shared/Tooltip';

export const LeftNavHeading: FC<{ title: string }> = ({ title }) => <div className="border-b px-4 pb-2 font-medium">{title}</div>;
export const Property: FC<{ title: string | ReactElement; value: number | string | ReactElement }> = ({ title, value }) => (
  <div className="text-dpm-14 my-4 flex flex-col items-start justify-between gap-2 px-4">
    <div className="font-medium">{title}</div>
    <div
      className="text-left"
      style={{
        wordBreak: 'break-word',
        overflowWrap: 'break-word',
      }}
    >
      {value}
    </div>
  </div>
);
export const WrappedInfo: FC<{ text: string }> = ({ text }) => (
  <Tooltip text={text}>
    {(tooltip) => (
      <span {...tooltip}>
        <InfoIcon className="h-4 w-4" />
      </span>
    )}
  </Tooltip>
);
