import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import usePermissions from '../../hooks/permissions/usePermissions';
import { ClientFormSectionInfo } from '../../models/ClientForm';
import { ClientFormSectionStatus, sectionStatusBadgeBackgrounds, sectionStatusKeys } from '../../models/ClientFormSectionStatus';
import { ClientFormTag, ClientFormUser } from '../../models/ClientFormUser';
import { ClientFormUserRole } from '../../models/ClientFormUserRoles';
import { FormSection, Progress } from '../../models/Form';
import { Roles } from '../../models/Role';
import User from '../../models/User';
import ClientFormService from '../../services/ClientFormService';
import hasFormRole from '../../utils/FormPermissionUtils';
import LanguageUtils from '../../utils/LanguageUtils';
import FormOwnership from '../ownership/FormOwnership';
import { OwnershipDisplayType } from '../ownership/OwnershipProps';
import CircleTickIcon from '../shared/icon/CircleTickIcon';
import ProgressBar from '../shared/ProgressBar';
import { Heading, HeadingSize } from '../shared/text/Heading';
import Tooltip from '../shared/Tooltip';
import InfoIcon from '../shared/icon/InfoIcon';
import Badge from '../shared/badge/Badge';
import { useCurrentUser } from '../../global-state/Auth';
import { useClientFormUserTags, useCurrentFormUsers, useFormSectionUsers } from '../../global-state/Forms';
import { useShallow } from 'zustand/react/shallow';

export type FormStepProps = {
  clientFormId: string;
  section: FormSection;
  hasPreviousStep: boolean;
  selected: boolean;
  enabled: boolean;
  progress: Progress;
  onClick?: (section: FormSection) => void;
  info: ClientFormSectionInfo | null | undefined;
  currentUserPermissionChanged?: () => void;
};

const FormStep: FC<FormStepProps> = (props) => {
  const { section, selected, onClick, clientFormId, progress, enabled, info, currentUserPermissionChanged } = props;

  const { t } = useTranslation(['common', 'form']);

  const currentUser = useCurrentUser((x) => x.value);
  const formUsers = useCurrentFormUsers((x) => x.value);
  const [formSectionUsers, setFormSectionUsers] = useFormSectionUsers(useShallow((x) => [x.value, x.setValue]));
  const [formTags, setFormTags] = useClientFormUserTags(useShallow((x) => [x.value, x.setValue]));
  const stepRef = useRef<HTMLDivElement>(null);
  const hasPermission = usePermissions();

  useEffect(() => {
    if (selected) {
      setTimeout(() => stepRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' }), 100);
    }
  }, [selected]);

  const currentFormUser = useMemo(() => ClientFormService.getCurrentFormUser(formUsers, currentUser as User), [currentUser, formUsers]);
  const currentFormStepUser = useMemo(
    () => ClientFormService.getCurrentFormUser(formSectionUsers, currentUser as User, section.id),
    [currentUser, formSectionUsers, section.id],
  );

  const filteredFormSectionUsers = useMemo(() => {
    return formSectionUsers.filter(
      (x) => x.formSectionId === section.id && (!section.requiresApproval ? x.role !== ClientFormUserRole.Approver : true),
    );
  }, [formSectionUsers, section.id, section.requiresApproval]);

  const filteredFormSectionTags = useMemo(() => {
    const userTags = filteredFormSectionUsers.flatMap((x) => x.tagIds ?? []);
    return formTags.filter((x) => userTags.includes(x.id));
  }, [filteredFormSectionUsers, formTags]);

  const setFilteredFormSectionUsers = useCallback(
    (users: ClientFormUser[], tags: ClientFormTag[]) => {
      setFormSectionUsers((prev) => [...prev.filter((x) => x.formSectionId !== section.id), ...users]);
      setFormTags((prev) => [...prev.filter((x) => x.formSectionId !== section.id), ...tags]);
    },
    [section.id, setFormSectionUsers, setFormTags],
  );

  const selectedCss = useMemo(
    () =>
      selected
        ? 'shadow-xl scale-100 border-l-[4px]'
        : `scale-95 ${enabled ? 'border-l-0 hover:border-l-[4px] pr-[4px] hover:pr-0 shadow-lg hover:shadow-xl hover:scale-100' : ''}`,
    [enabled, selected],
  );
  const disabledCss = useMemo(() => (enabled ? '' : 'opacity-60 cursor-not-allowed select-none'), [enabled]);

  const stepTitle = useMemo(
    () => `${section.number} ${LanguageUtils.getTranslation('title', section.translations)}`,
    [section.number, section.translations],
  );

  const hasVisibleQuestions = useMemo(() => section.actions.some((x) => x.visible), [section.actions]);
  if (!hasVisibleQuestions) {
    return null;
  }

  return (
    <article
      ref={stepRef}
      className={`${
        onClick && 'cursor-pointer'
      } ${selectedCss} ${disabledCss} border-l-primary-1 mb-3 rounded-[5px] bg-white transition-[border_padding] duration-150 ease-linear`}
      onClick={() => enabled && onClick && onClick(section)}
    >
      <div className="flex items-center justify-between px-4 py-2">
        <Tooltip text={stepTitle} truncatedTextMode>
          {(tooltip) => (
            <div {...tooltip} className="flex gap-1 truncate">
              <div className="max-w-full truncate">
                <Heading size={HeadingSize.CUSTOM} className={`text-dpm-14 flex-grow font-medium text-black`}>
                  {stepTitle}
                </Heading>
              </div>
              {section.requiresApproval && (
                <Tooltip text={<>{t(sectionStatusKeys[info?.status ?? ClientFormSectionStatus.NotSubmitted])}</>}>
                  {(tooltip) => (
                    <span {...tooltip} data-cy="status-icon">
                      <CircleTickIcon
                        className={`-mt-2 h-5 w-5 ${info?.status === ClientFormSectionStatus.Approved ? 'text-semantic-1' : 'opacity-50'}`}
                      />
                    </span>
                  )}
                </Tooltip>
              )}
            </div>
          )}
        </Tooltip>

        <div className="flex w-1/4 items-center justify-end">
          <FormOwnership
            size={OwnershipDisplayType.Icon}
            modalHeading={t('form:section-users.modal-heading')}
            users={filteredFormSectionUsers}
            tags={filteredFormSectionTags}
            formStepId={section.id}
            associatedId={clientFormId}
            onUsersChange={setFilteredFormSectionUsers}
            requiresApproval={section.requiresApproval}
            onCurrentUserRoleChange={currentUserPermissionChanged}
            onRemoveCurrentUser={currentUserPermissionChanged}
            requiresValidation={false}
            viewOnly={
              !hasPermission([Roles.TeamLead]) &&
              !hasFormRole(
                [ClientFormUserRole.Owner, ClientFormUserRole.Validator, ClientFormUserRole.Approver, ClientFormUserRole.Contributor],
                currentFormUser,
              ) &&
              !hasFormRole([ClientFormUserRole.Approver, ClientFormUserRole.Contributor, ClientFormUserRole.Viewer], currentFormStepUser)
            }
          />
        </div>
      </div>

      <div className="px-4 pb-4">
        {progress.totalRequired !== 0 && info && info.status !== ClientFormSectionStatus.NotSubmitted && (
          <Badge
            text={t(sectionStatusKeys[info?.status])}
            textClass="text-white"
            backgroundClass={sectionStatusBadgeBackgrounds[info?.status]}
          ></Badge>
        )}
        {(!info ||
          info.status === ClientFormSectionStatus.NotSubmitted ||
          (progress.totalRequired === 0 && info.status === ClientFormSectionStatus.Completed)) && (
          <>
            <span className="text-dpm-12 text-color-2 font-medium">
              {progress.totalRequired !== 0 &&
                t('form:left-tabs.overview.progress', { Answered: progress.totalAnswers, Total: progress.totalRequired })}
              {progress.totalRequired === 0 && (
                <>
                  {t('form:left-tabs.overview.step-not-required')}{' '}
                  <Tooltip text={t('form:left-tabs.overview.step-not-required-tooltip')}>
                    {(tooltip) => (
                      <span {...tooltip}>
                        <InfoIcon className="h-4 w-4" />
                      </span>
                    )}
                  </Tooltip>
                </>
              )}
            </span>
            <ProgressBar
              disabled={progress.totalAnswers === 0 || progress.totalRequired === 0}
              progress={progress.totalRequired === 0 ? 0 : progress.totalAnswers / progress.totalRequired}
            />
          </>
        )}
      </div>
    </article>
  );
};

export default FormStep;
